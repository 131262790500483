@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?ijnq1y");
  src: url("fonts/icomoon.eot?ijnq1y#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?ijnq1y") format("truetype"),
    url("fonts/icomoon.woff?ijnq1y") format("woff"),
    url("fonts/icomoon.svg?ijnq1y#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-style: italic;
}

html,
body {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.app {
  position: relative;
}

header {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

header {
  z-index: 15;
  position: absolute;
  padding: 1em;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

header .logo {
  width: 5em;
  height: 5em;
}

header .navbar .link {
  color: #839a4c;
  padding-left: 1em;
  text-decoration: none;
}

header .navbar .link:hover {
  color: #ee7348;
}

footer {
  color: #fff;
  margin-top: auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: #2e5580;
  align-items: start;
  padding: 2em;
  margin-top: 1em;
}

footer .info {
  flex: 1;
  display: flex;
  flex-direction: column;
}

footer .info .col {
  display: flex;
  flex-direction: column;
  justify-content: start;
  gap: 1em;
  align-items: start;
}

footer .services {
  flex: 1;
}

footer .contact {
  display: flex;
  padding-left: 2em;
  flex: 1;
  justify-content: start;
  flex-direction: column;
  align-items: start;
}

footer .contact div {
  display: flex;
  flex: 1;
  justify-content: center;
  gap: 2em;
  align-items: center;
}

footer .contact a {
  font-size: 2em;
  text-decoration: unset;
  color: #fff;
}

footer .contact a:hover {
  color: #839a4c;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  gap: 1em 0;
}

.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  gap: 1em 0;
}

.home img {
  z-index: 5;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.home .text {
  position: absolute;
  z-index: 10;
  top: 6em;
  font-weight: bold;
  color: #fff;
  box-shadow: #16161f;
}

.home .col {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  gap: 1em 1em;
  padding: 0.5em;
}

.home .col:nth-child(even) {
  background-color: #eee;
}

.home .col img {
  max-height: 30em;
  max-width: 40em;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
}

.home .col .box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.icon-phone::before {
  content: "\e942";
}
.icon-envelop::before {
  content: "\e945";
}
.icon-location2:before {
  content: "\e948";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-youtube:before {
  content: "\ea9d";
}

@media only screen and (max-width: 78em) {
  footer {
    flex-direction: column;
  }

  .home .col {
    flex-direction: column;
  }
  .home .text {
    top: 3em;
  }
}
